// in src/App.js
import React from 'react';
import { fetchUtils, Admin, Resource } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';


// import { EventInfoList, EventInfoShow } from './eventInfo';
// import { EventActionList, EventActionShow } from './eventAction';
import { Event1List, Event1Show} from './event-zzz-sce';
import { Event2List, Event2Show} from './event-zzz-auth';
import { Event3List, Event3Show} from './event-zzz-ope';

//import Dashboard from './Dashboard';
import authProvider from './authProvider';

import UserIcon from '@material-ui/icons/Group';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
}

//dev: https://api-sce-dev.proto-app.net/v1
//prd: https://api-sce.proto-app.net/v1
const dataProvider = jsonServerProvider(process.env.REACT_APP_CORE_URL,httpClient);
var sceFinSource = "events/"+process.env.REACT_APP_GLOBAL_ENV+"-sce-fin-source"
var zzzAuthSource = "events/"+process.env.REACT_APP_GLOBAL_ENV+"-zzz-auth-source"
var zzzOpeSource = "events/"+process.env.REACT_APP_GLOBAL_ENV+"-zzz-ope-source"

//https://api-sce-dev.proto-app.net/v1
//https://e3d8y6aj8e.execute-api.eu-west-3.amazonaws.com/dev
//restClient={jsonServerRestClient('https://api-sce-dev.proto-app.net/v1')}
// authProvider={authProvider}>  dashboard={Dashboard} 
//authProvider={authProvider}
//customRoutes={customRoutes} appLayout={MyLayout}  
// const App = () => (
//     <Admin authProvider={authProvider} dataProvider={dataProvider}  >
//         <Resource name="events/infos" options={{ label: 'Events - Info' }} list={EventInfoList} show={EventInfoShow} icon={UserIcon}/>
//         <Resource name="events/actions" options={{ label: 'Events - Action' }} list={EventActionList} show={EventActionShow} icon={UserIcon}/>
//     </Admin>
// );
// show={EventZzzSceShow}
//<Resource name="events" options={{ label: 'Events - zzz-sce' }} list={EventZzzSceList} icon={UserIcon}/>
const App = () => (
    <Admin authProvider={authProvider} dataProvider={dataProvider}  >
        <Resource name={sceFinSource} options={{ label: 'source: sce-fin' }} list={Event1List} show={Event1Show} icon={UserIcon}/>
        <Resource name={zzzAuthSource} options={{ label: 'source: zzz-auth' }} list={Event2List} show={Event2Show} icon={UserIcon}/>
        <Resource name={zzzOpeSource} options={{ label: 'source: zzz-ope' }} list={Event3List} show={Event3Show} icon={UserIcon}/>
    </Admin>
);

export default App;


//https://marmelab.com/react-admin/DataProviders.html
// const httpClient = (url, options = {}) => {
//   options.user = {
//       authenticated: true,
//       token: 'SRTRDFVESGNJYTUKTYTHRG'
//   }
//   return fetchUtils.fetchJson(url, options);
// }
// const dataProvider = simpleRestProvider('http://path.to.my.api/', httpClient);
